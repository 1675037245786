jQuery(function($) {

    $(window).on('load', function() {
        let playBtn = $('.vid-btn');
        playBtn.on('click touch', function() {
            let videos = $(this).parents('.saia--hero-videobg').find('video');

            if ($(this).hasClass('paused')) {
                $(videos).each(function(i, videoElement) {
                    $(videoElement).trigger('pause');
                });
                $(this).closest('.vid-btn-container').addClass('play-bg');
                $(this).removeClass('paused');
                
            } else {
                $(videos).each(function(i, videoElement) {
                    $(videoElement).trigger('play');
                });
                $(this).closest('.vid-btn-container').removeClass('play-bg');
                $(this).addClass('paused');
            }
        });
    });
    
});